import React from "react";

export default function App() {
    return (
        <div>

            <a
                href="https://github.com/LeticiaRossett/portfolio/raw/main/src/img/Leticia_Rossett_Resume.pdf"
                download
            >

                <button>
                    Download Resume
                </button>
            </a>
        </div>
    );
}

